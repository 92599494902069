export const orderCount = {
  namespaced: true,
  state: {
    currentOrdersCount: {
      currentOrderCount: 0,
      confirmOrderCount: 0,
      driverOutOrderCount: 0,
      paymentPendingOrderCount: 0
    },
    preOrdersCount: {
      currentOrderCount: 0,
      confirmOrderCount: 0,
      driverOutOrderCount: 0,
      paymentPendingOrderCount: 0
    }
  },
  actions: {
    setCurrentOrdersCount (context, payload) {
      context.commit('SET_CURRENT_ORDERS_COUNT', payload)
    },
    setPreOrdersCount (context, payload) {
      context.commit('SET_PRE_ORDERS_COUNT', payload)
    }
  },
  mutations: {
    SET_CURRENT_ORDERS_COUNT (state, payload) {
      state.currentOrdersCount = payload
    },
    SET_PRE_ORDERS_COUNT (state, payload) {
      state.preOrdersCount = payload
    }
  },
  getters: {
    currentOrdersCount: state => state.currentOrdersCount,
    preOrdersCount: state => state.preOrdersCount
  }
}
