import { initializeApp } from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
const firebaseAppInit = initializeApp({
  apiKey: 'AIzaSyBBzGr7yj6J4pZl8XBA-HkKoRMDwJUAekU',
  authDomain: 'toktok-1df1f.firebaseapp.com',
  databaseURL: 'https://toktok-1df1f.firebaseio.com',
  projectId: 'toktok-1df1f',
  storageBucket: 'toktok-1df1f.appspot.com',
  messagingSenderId: '173590753307',
  appId: '1:173590753307:web:0e523211db66ca95bb88be',
  measurementId: 'G-SM16DLZK82'
})

export const firebaseApp = firebaseAppInit
