import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { order } from './order'
import { fbase } from './fbase'
import { orderCount } from './orderCount'

Vue.use(Vuex)

export const store = new Vuex.Store({
  mutations: vuexfireMutations,
  modules: {
    fbase,
    order,
    orderCount
  }
})
