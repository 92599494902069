import Vue from 'vue'
import Router from 'vue-router'
import { getUser } from '@/helpers/auth.js'
import { AmplifyEventBus } from 'aws-amplify-vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/main',
      component: () => import('@/layouts/layout'),
      children: [
        { path: '/', component: () => import('@/pages/Main/start.vue'), name: 'main', meta: { requiresAuth: true } },
        { path: '/call/:trunk/:phone', component: () => import('@/pages/Main/home'), name: 'maincall', meta: { requiresAuth: true } },
        { path: '/address', component: () => import('../components/address'), name: 'address', meta: { requiresAuth: true } },
        { path: '/address/:trunk/:phone', component: () => import('../components/address'), name: 'addresscall', meta: { requiresAuth: true } },
        { path: '/complaints', component: () => import('@/pages/Complaint/complaints'), name: 'complaints', meta: { requiresAuth: true } },
        { path: '/complaints/:trunk/:phone', component: () => import('@/pages/Complaint/complaints'), name: 'complaintscall', meta: { requiresAuth: true } },
        { path: '/pdDialog', component: () => import('@/pages/TokTokMall/component/pdDialog'), name: 'pdDialog', meta: { requiresAuth: true } },
        { path: '/pdDialog/:trunk/:phone', component: () => import('@/pages/TokTokMall/component/pdDialog'), name: 'pdDialogcall', meta: { requiresAuth: true } },
        { path: '/refer', component: () => import('@/pages/Refer/refer'), name: 'refer', meta: { requiresAuth: true } },
        { path: '/refer/:trunk/:phone', component: () => import('@/pages/Refer/refer'), name: 'refercall', meta: { requiresAuth: true } },
        { path: '/toktokOrdersNew', component: () => import('@/pages/Order/OrderList'), name: 'toktokOrdersNew', meta: { requiresAuth: true } },
        { path: '/toktokOrderscallNew/:trunk/:phone', component: () => import('@/pages/Order/OrderList'), name: 'toktokOrderscallNew', meta: { requiresAuth: true } },
        { path: '/toktokPreOrdersNew', component: () => import('@/pages/Order/PreOrders/preOrders'), name: 'toktokPreOrdersNew', meta: { requiresAuth: true } },
        { path: '/toktokPreOrderscallNew/:trunk/:phone', component: () => import('@/pages/Order/PreOrders/preOrders'), name: 'toktokPreOrderscallNew', meta: { requiresAuth: true } },
        { path: '/pdProcess', component: () => import('@/pages/TokTokMall/pdOrders'), name: 'pdProcess', meta: { requiresAuth: true } },
        { path: '/pdProcess/:trunk/:phone', component: () => import('@/pages/TokTokMall/pdOrders'), name: 'pdProcesscall', meta: { requiresAuth: true } },
        { path: '/driverConfig', component: () => import('@/pages/Driver/driverConfig'), name: 'driverConfig', meta: { requiresAuth: true } },
        { path: '/editComplaints', component: () => import('@/pages/Complaint/component/editComplaints'), name: 'editComplaints', meta: { requiresAuth: true } },
        { path: '/editReasons', component: () => import('@/pages/Reason/editReasons'), name: 'editReasons', meta: { requiresAuth: true } },
        { path: '/blackList', component: () => import('@/pages/BlackList/blackList'), name: 'blackList', meta: { requiresAuth: true } },
        { path: '/sms', component: () => import('@/pages/sms'), name: 'sms', meta: { requiresAuth: true } },
        { path: '/driverTime', component: () => import('@/pages/DriverTimeSchedule/DriverTime'), name: 'DriverTime', meta: { requiresAuth: true } },
        { path: '/cgw-logs', component: () => import('@/pages/CorporateGateway'), name: 'CorporateGatewayLogs', meta: { requiresAuth: true } }
      ]
    },
    { path: '/', redirect: { name: 'main' } },
    { path: '/signIn', name: 'signIn', component: () => import('@/pages/login.vue'), meta: { requiresAuth: false } },
    { path: '/signUp', name: 'signUp', component: () => import('@/pages/signUp.vue'), meta: { requiresAuth: false } },
    { path: '*', redirect: { name: 'main' } }
  ]
})
AmplifyEventBus.$on('authState', async (state) => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    confirmSignUp: () => {
      router.push({ path: '/signUpConfirm' })
    },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: () => {
      router.push({ path: '/' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  if (!user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    if (to.matched.some((record) => typeof (record.meta.requiresAuth) === 'boolean' && !record.meta.requiresAuth)) {
      return next({
        path: '/'
      })
    }
  }
  return next()
})

export default router
