import { firebaseAction, firestoreAction } from 'vuexfire'
import { firebaseApp } from '@/helpers/firebase'
import 'firebase/database'
export const fbase = {
  namespaced: true,
  state: () => ({
    RTDBOrders: [],
    RTDBPreOrders: [],
    RTDBDrivers: [],
    RTDBConfirmOrders: [],
    RTDBDriverOutOrders: [],
    RTDBInKitchenOrders: []
  }),
  actions: {
    logOut (context) {
      context.commit('logOut')
    },
    setUser (context, payload) {
      context.commit('setUserData', payload)
    },
    setIsLoggedIn (context, payload) {
      context.commit('setIsLoggedIn', payload)
    },
    setOrders (context, payload) {
      context.commit('setOrders', payload)
    },
    setDrivers (context, payload) {
      context.commit('setDrivers', payload)
    },
    setPreOrders (context, payload) {
      context.commit('setPreOrders', payload)
    },
    bindOrdersRef: firebaseAction(({ bindFirebaseRef, state }) => {
      bindFirebaseRef('RTDBOrders', firebaseApp.database().ref('ordersDev').orderByChild('origin').equalTo('TOKTOK'))
    }),
    unBindDriverOutOrdersRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.log('unbindFirestoreRef', 'RTDBDriverOutOrders')
      unbindFirestoreRef('RTDBDriverOutOrders')
    }),
    unBindInKitchenOrdersRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.log('unbindFirestoreRef', 'RTDBInKitchenOrders')
      unbindFirestoreRef('RTDBInKitchenOrders')
    }),
    bindPreOrdersRef: firebaseAction(({ bindFirebaseRef, state }) => {
      bindFirebaseRef('RTDBPreOrders', firebaseApp.database().ref('preOrders').orderByChild('origin').equalTo('TOKTOK'))
    }),
    unBindPreOrdersRef: firebaseAction(({ unbindFirebaseRef }) => {
      unbindFirebaseRef('preOrders')
    }),
    bindDrivers: firebaseAction(({ bindFirebaseRef }) => {
      bindFirebaseRef('RTDBDrivers', firebaseApp.database().ref('drivers'))
    }),
    unbindDrivers: firebaseAction(({ unbindFirebaseRef }) => {
      unbindFirebaseRef('RTDBDrivers')
    }),
    setOrderStatus (context, payload) {
      firebaseAction(({ state }) => {
        const order = { ...state.order }
        order.order_number = payload.order_number
        delete order['.key']
        firebaseApp.database().ref('ordersDev').child(payload.key).set(order)
      })
    },
    clearOrders: ({ commit }) => {
      commit('unSub')
    }
  },
  mutations: {
    setUserData (state, payload) {
      state.user = payload
    },
    logOut (state) {
      state.user = {}
      state.isLoggedIn = false
    },
    setIsLoggedIn (state, payload) {
      state.isLoggedIn = payload
    },
    setOrders (state, payload) {
      state.RTDBOrders = payload
    },
    setDrivers (state, payload) {
      state.RTDBDrivers = payload
    },
    setPreOrders (state, payload) {
      state.RTDBPreOrders = payload
    },
    unSub (state) {
      state.orders = []
    },
    SET_ORDER_STATUS (state, payload) {
      state.order.order_number = payload
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    RTDBOrders: state => {
      return state.RTDBOrders
    },
    RTDBConfirmOrders: state => {
      return state.RTDBConfirmOrders
    },
    RTDBDriverOutOrders: state => {
      return state.RTDBDriverOutOrders
    },
    RTDBInKitchenOrders: state => {
      return state.RTDBInKitchenOrders
    },
    RTDBPreOrders: state => {
      return state.RTDBPreOrders
    },
    RTDBDrivers: state => {
      return state.RTDBDrivers
    },
    getOrderDetailByRefer: function (state, orderRefer) {
      return function (orderRefer) {
        return state.RTDBOrders.find(order => order.order_refer === orderRefer)
      }
    },
    currentOrder: state => {
      return state.order
    },
    ordersCount: state => {
      return state.RTDBOrders.filter(el => el.order_status >= 20 && el.order_status <= 31 && !Object.prototype.hasOwnProperty.call(el, 'driver_out')).length ? state.RTDBOrders.filter(el => el.order_status >= 20 && el.order_status <= 31 && !Object.prototype.hasOwnProperty.call(el, 'driver_out')).length : 0
    },
    preOrdersCount: state => {
      return state.RTDBPreOrders.length ? state.RTDBPreOrders.length : 0
    },
    orders: state => {
      // return state.RTDBOrders.filter(el => el.order_status === 20)
      return state.RTDBOrders.filter(el => el.order_status >= 20 && el.order_status <= 31 && !Object.prototype.hasOwnProperty.call(el, 'driver_out'))
    },
    ordersDriverOut: state => {
      return state.RTDBOrders.filter(el => Object.prototype.hasOwnProperty.call(el, 'driver_out'))
    },
    ordersConfirm: state => {
      return state.RTDBOrders.filter(el => el.order_status === 11)
    },
    ordersDriverOutCount: state => {
      return state.RTDBOrders.filter(el => Object.prototype.hasOwnProperty.call(el, 'driver_out')).length ? state.RTDBOrders.filter(el => Object.prototype.hasOwnProperty.call(el, 'driver_out')).length : 0
    },
    ordersConfirmCount: state => {
      return state.RTDBOrders.filter(el => el.order_status === 11).length ? state.RTDBOrders.filter(el => el.order_status === 11).length : 0
    },
    ordersInKitchen: state => {
      // return state.RTDBOrders.filter(el => el.order_status >= 30 && !Object.prototype.hasOwnProperty.call(el, 'driver_out'))
      return []
    }
  }
}
