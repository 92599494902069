export const order = {
  namespaced: true,
  state: {
    inCart: []
  },
  actions: {
    addToCart (context, invId) {
      context.commit('ADD_TO_CART', invId)
    },
    addToCartSingle (context, invId) {
      context.commit('ADD_TO_CART_SINGLE', invId)
    },
    removeFromCart (context, index) {
      context.commit('REMOVE_FROM_CART', index)
    },
    clearCart (context) {
      context.commit('CLEAR_CART')
    }
    // changeOrderTotal (context, index, total) {
    //   context.commit('CHANGE_ORDER_TOTAL', index, total)
    // }
  },
  mutations: {
    ADD_TO_CART (state, item) {
      if (state.inCart.find(x => x.pkey === item.pkey && x.parent === item.parent && x.type === 'addon')) {
        const result = state.inCart.find(x => x.pkey === item.pkey && x.parent === item.parent)
        const index = state.inCart.findIndex(x => x.pkey === item.pkey && x.parent === item.parent)
        result.count += item.count
        // result.price += item.price
        state.inCart[index] = JSON.parse(JSON.stringify(result))
      } else {
        state.inCart.push(item)
      }
    },
    ADD_TO_CART_SINGLE (state, item) {
      if (state.inCart.find(x => x.pkey === item.pkey && x.parent === 0)) {
        const index = state.inCart.findIndex(x => x.pkey === item.pkey && x.parent === 0)
        state.inCart[index].count += parseInt(item.count)
      }
    },
    REMOVE_FROM_CART (state, index) {
      const menu = state.inCart[index]
      state.inCart.splice(index, 1)
      state.inCart = state.inCart.filter(item => menu.index !== item.parent)
    },
    CLEAR_CART (state) {
      state.inCart = []
    }
  },
  getters: {
    inCart: state => state.inCart,
    getItems: function (state, parent) {
      return function (parent) {
        return state.inCart.filter((item) => (item.parent === parent && item.type === 'item'))
      }
    },
    getAddons: function (state, parent) {
      return function (parent) {
        return state.inCart.filter((item) => item.parent === parent && item.type === 'addon')
      }
    }
  }
}
