import { Notification, Message } from 'element-ui'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Ulaanbaatar')
export function showNotif (type, title, msg) {
  Notification({
    title: title,
    message: msg,
    type: type
  })
}
export function showMessage (type, msg) {
  Message({
    showClose: true,
    center: true,
    message: msg,
    type: type
  })
}
export function inArray (needle, haystack) {
  const length = haystack.length
  for (let i = 0; i < length; i++) {
    if (haystack[i] === needle) return true
  }
  return false
}
export function formatPrice (number) {
  const val = (number / 1).toFixed(0).replace('.', '')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export function timeSince (datestring) {
  const date1 = dayjs(datestring)
  const date2 = dayjs().tz('Asia/Ulaanbaatar')
  let seconds = date2.diff(date1)
  seconds = seconds / 1000

  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ' жил'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + ' сар'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + ' өдөр'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + ' цаг'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + ' минут'
  }
  return Math.floor(seconds) + ' секунд'
}
export function timeTo (datestring) {
  const date = new Date(datestring)
  const seconds = Math.floor((date - new Date()) / 1000)

  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ' жил'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + ' сар'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + ' өдөр'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + ' цаг'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + ' минут'
  }
  return Math.floor(seconds) + ' секунд'
}
export function contains (target, pattern) {
  let value = false
  pattern.forEach(function (word) {
    if (target.includes(word)) {
      value = true
    }
  })
  return value
}

export function arrayIncludes (baseArray, searchArray) {
  let isFound = false
  searchArray.forEach(element => {
    if (baseArray.includes(element)) {
      isFound = true
    }
  })
  return isFound
}

export function checkProperty (obj, field) {
  return Object.prototype.hasOwnProperty.call(obj, field)
}
export function checkNotEmpty (data) {
  return !['', null, undefined].includes(data)
}
