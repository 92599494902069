import Vue from 'vue'
import router from './router'
import { store } from './store'
import App from './App'
import ElementUI from 'element-ui'
// import ReadMore from 'vue-read-more'
import VueBus from 'vue-bus'
import locale from 'element-ui/lib/locale/lang/mn'
import 'element-ui/lib/theme-chalk/index.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { Auth } from 'aws-amplify'
import awsconfig from './aws-exports'
// import _service from './helpers/services'
import request from './helpers/request'
import { showMessage, showNotif, checkProperty, checkNotEmpty, arrayIncludes, formatPrice, timeSince } from './helpers/helper'
Auth.configure(awsconfig)
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyBBzGr7yj6J4pZl8XBA-HkKoRMDwJUAekU',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})

Vue.use(ElementUI, { locale })
Vue.use(Auth)
// Vue.use(ReadMore)
Vue.use(VueBus)
Vue.prototype.$api = request
Vue.prototype.$showMessage = showMessage
Vue.prototype.$showNotif = showNotif
Vue.prototype.$checkProperty = checkProperty
Vue.prototype.$checkNotEmpty = checkNotEmpty
Vue.prototype.$arrayIncludes = arrayIncludes
Vue.prototype.$formatPrice = formatPrice
Vue.prototype.$timeSince = timeSince

Vue.config.productionTip = false
new Vue({
  router,
  store,
  components: { App },
  data: {
    brand: null,
    trunks: [],
    phone: null,
    trunk: null,
    called: false,
    is_approved_address: false,
    full_address: '',
    service: null,
    orderJson: {
      address: {
        addr_orts: '',
        addr_orts_code: '',
        addr_haalga: '',
        addr_note: '',
        bairname: '',
        bairname2: '',
        bairnote: '',
        longitude: '',
        latitude: '',
        podcode: '',
        podph: '',
        podkfc: '',
        podtoktok: ''
      },
      options: {
        origin: '',
        channel: 'new CC',
        order_type: 0,
        advance_order: 0,
        promised_time: '',
        phone2: '',
        pay_type: '',
        customer_note: '',
        vat_type: 1,
        vat_regno: '',
        vat_cust_name: '',
        employee: ''
      },
      cust: {
        phone: ''
      },
      cart: [],
      outlet: '',
      outlet_address: '',
      outlet_longitude: '',
      outlet_latitude: ''
    },
    outlets: [],
    roles: [],
    email: '',
    drivers: [],
    kpi: {},
    ordersCount: {
      asap: {
        current: 0,
        driver_out: 0,
        payment_pending: 0,
        pre_orders_pending: 0
      },
      preorder: {
        current: 0,
        driver_out: 0,
        payment_pending: 0,
        pre_orders_pending: 0
      }

    },
    whitespace: '\xa0'
  },
  created () {
    // this.getAllOutletNames()
    this.getDrivers()
    this.kpiList()
    // this.preOrders()
  },
  methods: {
    loader () {
      return this.$loading({
        lock: true,
        text: 'Уншиж байна',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    async getDrivers () {
      try {
        const { data } = await request.get('other/drivers' + '?status=active')
        this.drivers = data
      } catch (err) {
        this.$notify.error({
          title: 'Алдаа',
          message: err.message
        })
      }
    },
    async getOrdersCount (advanceOrder) {
      try {
        const { data } = await request.get(`orders/count?advance_order=${advanceOrder}`)
        if (advanceOrder === 'preorder') {
          this.$store.dispatch('orderCount/setPreOrdersCount', data)
        }
        if (advanceOrder === 'asap') {
          this.$store.dispatch('orderCount/setCurrentOrdersCount', data)
        }
        this.ordersCount[advanceOrder] = data
      } catch (err) {
        let message = err.message
        if (Object.prototype.hasOwnProperty.call(err, 'response') && Object.prototype.hasOwnProperty.call(err.response, 'data')) {
          message = err.response.data.message
        }
        this.$notify.error({
          title: 'Алдаа',
          message: message
        })
      }
    },
    // async getAllOutletNames () {
    //   await _service.getAllOutletName().then(response => {
    //     if (response.status === 'success') {
    //       this.outlets = response.data.sort((a, b) => (a.outlet_name > b.outlet_name) ? 1 : ((b.outlet_name > a.outlet_name) ? -1 : 0))
    //     } else {
    //       showNotif('Салбарууд авч чадсангүй')
    //     }
    //   }).catch(error => {
    //     console.log('getAllOutletNames error', error)
    //   })
    // },
    // showNotif (type, message) {
    //   if (type === 'success') {
    //     this.$notify.success({
    //       title: 'Амжилттай',
    //       message: message
    //     })
    //   } else {
    //     this.$notify.error({
    //       title: 'Алдаа',
    //       message: message
    //     })
    //   }
    // },
    async kpiList () {
      const kpi = await (request.get('other/kpi'))
      this.kpi = kpi.data.response
    }
  },
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
