import axios from 'axios'
import { getToken } from './auth'
import { Notification } from 'element-ui'
import { checkProperty, showMessage } from './helper'
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_CC_API}v1/`,
  timeout: 5000
}
)
instance.interceptors.request.use(
  async config => {
    if (!Object.prototype.hasOwnProperty.call(config.headers, 'Authorization') || !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${await getToken()}`
    }
    return config
  },
  error => {
    console.log('🚀 ~ file: request.js:16 ~ error:', error)
    return Promise.reject(error)
  }
)
instance.interceptors.response.use((response) => {
  if (checkProperty(response.data, 'message')) showMessage('', response.data.message)
  return response
}, (error) => {
  let message = error.message
  if (checkProperty(error, 'response') && checkProperty(error.response, 'data') && checkProperty(error.response.data, 'message')) {
    message = error.response.data.message
  }
  Notification.error({
    title: message
  })
  return Promise.reject(error.message)
})
export default instance
