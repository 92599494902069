import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

function getUser () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  }).catch(err => {
    console.log(err)
    return null
  })
}

function getUserName () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  }).catch(err => {
    console.log(err)
    return null
  })
}

function getGroups () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user.signInUserSession.accessToken.payload['cognito:groups']
    } else {
      return null
    }
  }).catch(err => {
    console.log(err)
    return null
  })
}

async function getToken () {
  return await Auth.currentSession().then((session) => {
    if (session) {
      return session.idToken.jwtToken
    } else {
      return null
    }
  }).catch(err => {
    console.log(err)
    return null
  })
}

function signUp (username, password) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      email: username
    }
  })
    .then(data => {
      AmplifyEventBus.$emit('localUser', data.user)
      if (data.userConfirmed === false) {
        AmplifyEventBus.$emit('authState', 'confirmSignUp')
      } else {
        AmplifyEventBus.$emit('authState', 'signIn')
      }
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

function confirmSignUp (username, code) {
  return Auth.confirmSignUp(username, code).then(data => {
    AmplifyEventBus.$emit('authState', 'signIn')
    return data
  })
    .catch(err => {
      console.log(err)
      throw err
    })
}

function resendSignUp (username) {
  return Auth.resendSignUp(username).then(() => {
    return 'SUCCESS'
  }).catch(err => {
    console.log(err)
    return err
  })
}

async function signIn (username, password) {
  try {
    const user = await Auth.signIn(username, password)
    if (user) {
      AmplifyEventBus.$emit('authState', 'signedIn')
    }
    return { status: 'success' }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      console.log(err)
    } else if (err.code === 'PasswordResetRequiredException') {
      console.log(err)
    } else if (err.code === 'NotAuthorizedException') {
      console.log(err)
    } else if (err.code === 'UserNotFoundException') {
      console.log(err)
    } else {
      console.log(err)
    }
    return { status: 'unSuccess' }
  }
}

function signOut () {
  return Auth.signOut()
    .then(data => {
      AmplifyEventBus.$emit('authState', 'signedOut')
      return data
    })
    .catch(err => {
      console.log(err)
      return err
    })
}
async function changePassword (oldPassword, newPassword) {
  const currentUser = await getUser()
  return Auth.changePassword(currentUser, oldPassword, newPassword)
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}

function getEmail () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user.attributes.email
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err)
    })
}
async function checkCurrentUserExist () {
  return Auth.currentUserInfo()
    .then(async data => {
      if (!data || !Object.prototype.hasOwnProperty.call(data, 'username')) {
        await Auth.signOut()
        AmplifyEventBus.$emit('authState', 'signedOut')
      }
      return data
    })
    .catch(async () => {
      await Auth.signOut()
      AmplifyEventBus.$emit('authState', 'signedOut')
    })
}
export { getUser, signUp, confirmSignUp, resendSignUp, signIn, signOut, getToken, getUserName, getGroups, changePassword, getEmail, checkCurrentUserExist }
